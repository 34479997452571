import React from "react";
import { Navigate, BrowserRouter as Router } from "react-router-dom";

import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { Routes, Route } from "react-router-dom";

import theme from "./theme";

import Admin from "./pages/Admin";
import Account from "./pages/Account";
import Main from "./pages/Main";
import Layout from "./pages/Layout";
import Service from "./pages/Service";
import Profile from "./pages/Profile";
import Callback from "./pages/Callback";


const ProtectedRoute = ({ children }) => {
  const token = localStorage.getItem('jwt');

  if (!token) {
    console.log('Redirecting to login');
    window.location.href = 'https://auth.psdi.ac.uk/realms/PSDI/protocol/openid-connect/auth?client_id=services&redirect_uri=https://services.psdi.ac.uk/callback/&response_type=code&scope=openid';
    return null;
  }

  return children;
};


function App() {
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Routes>
          <Route path="callback/*" element={<Callback />} />


          <Route path="/" element={<Layout />}>
            <Route index element={<Main />} />
            <Route path="service/:serviceName" element={<Service />} />
          </Route>


          <Route path="/" element={<ProtectedRoute><Layout /></ProtectedRoute>}>
            <Route path="admin" element={<Admin />} />
            <Route path="profile" element={<Profile />} />
          </Route>

          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </ThemeProvider>
    </Router>
  );
}

export default App;
