import React from 'react';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

export default function Profile() {
  return (
    <>
      <Typography variant="h3">Profile</Typography>
      <Paper
        sx={{ padding: "20px", paddingTop: "20px", marginTop: "30px", marginBottom: "30px" }}
        variant="outlined"
      >
      Basic user information...<br />
      </Paper>
    </>
  );
};