import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Callback = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');

    console.log('code', code);

    if (code) {

      fetch(`/api/auth/callback?code=${encodeURIComponent(code)}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => response.json())
      .then(data => {

        console.log('data part entered');
        console.log('data', data);

        ['firstname', 'lastname', 'email', 'jwt'].forEach(key => {
          if (data[key]) localStorage.setItem(key, data[key]);
        });

        if (data.jwt) {
          localStorage.setItem('jwt', data.jwt);
          // Navigate using the redirect value from session storage if available
          const redirectUrl = sessionStorage.getItem('redirect') || '/';
          navigate(redirectUrl);
        }
      });
    }
  }, [navigate]);

  return <div></div>;
};

export default Callback;
