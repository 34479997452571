import React, { useState } from 'react';
import { useParams, Navigate, useNavigate } from 'react-router-dom';
import services from '../services';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ImageViewer from './ImageViewer';


function Service() {
  const { serviceName } = useParams();
  const service = services[serviceName];
  const navigate = useNavigate();

  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [isImageViewerOpen, setIsImageViewerOpen] = useState(false);


  const handleThumbnailClick = (index) => {
    setSelectedImageIndex(index);
    setIsImageViewerOpen(true);
  };

  const closeImageViewer = () => {
    setIsImageViewerOpen(false);
  };

  if (!service) {
    return <Navigate to="/" />;
  }

  return (
    <Box
      width="100%"
      marginTop="30px"
    >
      <Button
        variant="text"
        onClick={() => navigate(-1)}
        startIcon={
          <Icon
            color="white"
            baseClassName="material-icons-outlined"
          >
            arrow_back
          </Icon>
        }
      >
        Back
      </Button>
      <Paper
        sx={{
          padding: '20px',
          paddingTop: '20px',
          marginTop: '10px',
          marginBottom: '30px'
        }}
        variant="outlined"
      >
        <Grid container={true}>
          <Grid
            item={true}
            xs={12}
            sm="2"
          >
            <img
              src={service.logo}
              width="150px"
            />
          </Grid>
          <Grid item={true}>
            <Stack
              direction="row"
              spacing="10px"
              alignItems="center"
            >
              <Typography variant="h3">{service.name}</Typography>
              <Checkbox
                icon={
                  <Icon
                    color="inherit"
                    sx={{ color: '#DADADA' }}
                    fontSize="large"
                  >
                    star
                  </Icon>
                }
                checkedIcon={
                  <Icon
                    color="warning"
                    sx={{ color: '#FFB600' }}
                    fontSize="large"
                  >
                    star
                  </Icon>
                }
              />
            </Stack>
          </Grid>
        </Grid>
        <Grid
          container={true}
          spacing={3}
          wrap="wrap-reverse"
        >
          <Grid
            item={true}
            xs="12"
            md="8"
          >
            <Box
              width="100%"
              padding="10px"
              paddingTop="20px"
            >
              <Divider
                variant="fullWidth"
                flexItem={true}
                orientation="horizontal"
                sx={{
                  width: '100%',
                  '&::before': { width: '0px !important' },
                  '.MuiDivider-wrapper': { paddingLeft: '0px !important' }
                }}
                textAlign="left"
              >
                <Typography
                  variant="h4"
                  paddingBottom="10px"
                >
                  About
                </Typography>
              </Divider>
              {service.about.map((item, index) => (
                <Typography
                  variant="body1"
                  paddingBottom="10px"
                >
                  {item}
                </Typography>
              ))}
            </Box>
            <Stack
              direction="row"
              spacing="16px"
              paddingTop="15px"
              paddingBottom="15px"
              sx={{
                overflowX: 'auto',
                justifyContent: { xs: 'flex-start', md: 'center' },
              }}
            >
              {service.screenshots.map((item, index) => (
                <div
                  onClick={() => handleThumbnailClick(index)}
                  style={{ cursor: 'pointer' }}
                >                  <Paper
                  elevation={2}
                  variant="elevation"
                  sx={{
                    borderColor: 'white',
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    '&:hover': {
                      borderColor: 'primary.main',
                    }
                  }}
                >
                    <img
                      src={item}
                      width="200px"
                    />
                  </Paper>
                </div>
              ))}
            </Stack>
            {isImageViewerOpen && (
              <ImageViewer
                imageUrls={service.screenshots}
                initialIndex={selectedImageIndex}
                onClose={closeImageViewer}
              />
            )}
            <Box
              width="100%"
              padding="10px"
              paddingTop="20px"
            >
              <Divider
                variant="fullWidth"
                flexItem={true}
                orientation="horizontal"
                sx={{
                  width: '100%',
                  '&::before': { width: '0px !important' },
                  '.MuiDivider-wrapper': { paddingLeft: '0px !important' }
                }}
                textAlign="left"
              >
                <Typography
                  variant="h4"
                  paddingBottom="10px"
                >
                  Guides
                </Typography>
              </Divider>
              {service.guides.map((item, index) => (
                <React.Fragment key={index}>
                  <ListItemButton onClick={() => window.open(item.url, '_blank')}>
                    <ListItemIcon>
                      <Icon color="primary" fontSize="large">
                        {item.icon}
                      </Icon>
                    </ListItemIcon>
                    <ListItemText primary={item.text} secondary="" />
                    <ListItemIcon sx={{ minWidth: '30px' }}>
                      <Icon>chevron_right</Icon>
                    </ListItemIcon>
                  </ListItemButton>
                  {index !== service.guides.length - 1 && <Divider />}
                </React.Fragment>
              ))}
            </Box>
          </Grid>
          <Grid
            item={true}
            xs="12"
            md="4"
          >
            <Paper
              sx={{ marginTop: '30px', padding: '20px' }}
              variant="outlined"
            >
              <Box
                width="100%"
                justifyContent="center"
                display="flex"
              >
                <Button
                  color="primary"
                  size="medium"
                  variant="contained"
                  sx={{ width: '130px' }}
                  onClick={() => window.open(service.url, '_blank')}
                  endIcon={
                    <Icon
                      color="white"
                      baseClassName="material-icons-outlined"
                    >
                      arrow_forward
                    </Icon>
                  }
                >
                  Visit
                </Button>
              </Box>
            </Paper>

{serviceName === 'learn' && (

            <Paper
              sx={{ marginTop: '30px', padding: '20px' }}
              variant="outlined"
            >
              <Typography variant="h6">Role</Typography>
              <Select
                value={['select']}
                fullWidth={true}
                sx={{ marginTop: '15px', marginBottom: '15px' }}
              >
                <MenuItem value="select">Student</MenuItem>
                <MenuItem value="developer">Teacher</MenuItem>
              </Select>
              <Box
                justifyContent="center"
                display="flex"
              >
                <Button
                  color="primary"
                  size="medium"
                  variant="contained"
                >
                  Request Role Change
                </Button>
              </Box>
            </Paper>
)}
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}

export default Service;