import React, { useState, useEffect } from 'react';
import { Outlet, Link, useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Icon from '@mui/material/Icon';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

export default function Layout() {
    const navigate = useNavigate();
    const [profileAnchor, setProfileAnchor] = useState(null);
    const [selectedButton, setSelectedButton] = useState(localStorage.getItem('selectedButton') || 'account');


    useEffect(() => {
        localStorage.setItem('selectedButton', selectedButton);
    }, [selectedButton]);

    const handleLogout = () => {
        setProfileAnchor(null);
        localStorage.removeItem('jwt');
        localStorage.removeItem('firstname');
        localStorage.removeItem('lastname');
        localStorage.removeItem('email');

        const keycloakLogoutUrl = `https://auth.psdi.ac.uk/realms/PSDI/protocol/openid-connect/logout?post_logout_redirect_uri=${encodeURIComponent('https://demo.psdi.ac.uk')}&client_id=psdi_account`;
        window.location.href = keycloakLogoutUrl;
    };
    return (
        <Box
            display="flex"
            flexGrow={1}
            flexDirection="column"
            sx={{ backgroundColor: '#FAFAFA', overtflow: 'scroll', minHeight: '100vh' }}
        >
            <Container
                sx={{ overtflow: 'scroll', marginTop: '30px', marginBottom: '30px' }}
                component="main"
            >
                <Stack
                    direction="row"
                    flexGrow={1}
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Link to="/">
                        <img
                            src={`${process.env.PUBLIC_URL}/logo.svg`}
                            width="200px"
                        />
                    </Link>
                    <Stack
                        direction="row"
                        spacing="16px"
                        height="30px"
                    >

                        {/*
                        <Button
                            color="primary"
                            size="large"
                            variant="outlined"
                            sx={{ borderRadius: 28, padding: '20px' }}
                            onClick={login}

                        >
                            <Icon sx={{ marginRight: '10px', marginLeft: '0px' }}>key</Icon>
                            <Typography variant="subtitle1">login</Typography>
                        </Button>
                        */}
                        <Button
                            color="primary"
                            size="large"
                            variant={selectedButton === 'admin' ? "contained" : "outlined"}
                            sx={{ borderRadius: 28, padding: '20px', visibility: 'hidden' }}
                            onClick={() => {
                                navigate("/admin");
                                setSelectedButton('admin');
                            }}
                        >
                            <Icon sx={{ marginRight: '10px', marginLeft: '0px' }}>key</Icon>
                            <Typography variant="subtitle1">Admin</Typography>
                        </Button>
                        <Button
                            color="primary"
                            size="large"
                            variant={selectedButton === 'account' ? "contained" : "outlined"}
                            sx={{ borderRadius: 28, padding: '20px' }}
                            id="profile"
                            onClick={(event) => {
                                if (selectedButton === 'admin') {
                                    navigate("/");
                                    setSelectedButton('account');
                                } else {
                                    setProfileAnchor(event.currentTarget);
                                    setSelectedButton('account');
                                }
                            }}
                        >
                            <Icon sx={{ marginRight: '10px', marginLeft: '0px' }}>person</Icon>
                            <Typography variant="subtitle1">{localStorage.getItem('firstname') || 'Login'}</Typography>
                        </Button>
                        <Menu
                            anchorEl={profileAnchor}
                            open={Boolean(profileAnchor)}
                            onClose={() => setProfileAnchor(null)}
                            autoFocus={false}
                            sx={{ marginTop: '10px' }}
                        >
                            <MenuItem
                                sx={{ width: '300px', margin: '10px' }}
                                onClick={() => {
                                    setProfileAnchor(null);
                                    navigate('/profile');
                                }}
                            >
                                <ListItemIcon>
                                    <Icon color="primary">person</Icon>
                                </ListItemIcon>
                                <ListItemText
                                    primary="Profile"
                                    secondary=""
                                />
                            </MenuItem>
                            <Divider
                                variant="fullWidth"
                                flexItem={true}
                                orientation="horizontal"
                            />
                            <MenuItem
                                sx={{ width: '300px', margin: '10px' }}
                                onClick={handleLogout}
                            >
                                <ListItemIcon>
                                    <Icon color="primary">logout</Icon>
                                </ListItemIcon>
                                <ListItemText
                                    primary="Logout"
                                    secondary=""
                                />
                            </MenuItem>
                        </Menu>
                    </Stack>
                </Stack>
                <Outlet />
            </Container>
        </Box>
    );
};