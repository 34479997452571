import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Paper from '@mui/material/Paper';


export default function Admin() {

  const [value, setValue] = useState(parseInt(localStorage.getItem('tab')) || 0);

  useEffect(() => {
    localStorage.setItem('tab', value.toString());
  }, [value]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      width="100%"
      marginTop="30px"
    >
      <Typography variant="h3">Admin</Typography>
      <Tabs
        value={value}
        onChange={handleChange}
        centered={true}
        sx={{ paddingTop: '20px', paddingBottom: '20px' }}
      >
        <Tab label="Overview" sx={{ fontSize: '18px' }} />
        <Tab label="Users" sx={{ fontSize: '18px' }} />
      </Tabs>


      {value === 0 && (
      <Paper
        sx={{ padding: "20px", paddingTop: "20px", marginBottom: "30px" }}
        variant="outlined"
      >
List of pending users verifications<br />
List of pending role requests<br />
Basic stats<br />
</Paper>
      )}


      {value === 1 && (
      <Paper
        sx={{ padding: "20px", paddingTop: "20px", marginBottom: "30px" }}
        variant="outlined"
      >List of all users<br />
User edit - can change any information eg. email, name, roles
</Paper>
      )}
    </Box>
  );
};