const services = {
    csd: {
        name: "Cambridge Structural Database",
        shortname: "CSD",
        logo: "/images/csd/logo.png",
        url: "https://csd.psdi.ac.uk/",
        about: [
            "WebCSD is an advanced online portal provided by the Cambridge Crystallographic Data Centre (CCDC) that offers access to the Cambridge Structural Database (CSD). The CSD is an extensive repository of over one million small molecule crystal structures obtained from X-ray and neutron diffraction studies. WebCSD allows researchers and scientists to perform comprehensive searches and analyses of molecular structures, facilitating detailed exploration and discovery in various scientific fields. This tool is particularly beneficial for those involved in chemistry, materials science, and pharmacology, enabling them to compare and analyze structural data effectively.",
            "One of the key capabilities of WebCSD is its robust search functionality, which includes similarity searches, substructure searches, and text/numeric searches. Users can set 3D constraints and utilize the database to find structures that meet specific geometric or chemical criteria. The portal is designed to be user-friendly, providing access to newly published structures and allowing users to keep their research up-to-date with the latest data. Additionally, WebCSD supports integration with other software tools, enhancing its utility in computational and data-driven research projects."
        ],
        guides: [
            {
                icon: "language",
                text: "WebCSD Documentation and Resources",
                url: "https://www.ccdc.cam.ac.uk/support-and-resources/documentation-and-resources/?category=All%20Categories&product=WebCSD&type=All%20Types"
            },
            {
                icon: "list_alt",
                text: "Fact Sheet",
                url: "https://www.psds.ac.uk/sites/default/files/2019-07/CSDv6.pdf"
            },
            {
                icon: "smart_display",
                text: "Introductory Video",
                url: "https://youtu.be/tbw1LGYmAQE"
            }
        ],
        screenshots: [
            "/images/csd/1.png",
            "/images/csd/2.png",
            "/images/csd/3.png",
        ]
    },
    propersea: {
        name: "Propersea",
        shortname: "Propersea",
        logo: "/images/propersea/logo.png",
        url: "https://propersea.psdi.ac.uk/",
        about: [
            "Propersea is an online tool designed for predicting a range of molecular and physicochemical properties for small molecules. It supports input through SMILES strings, InChI identifiers, and structure drawings. The predicted properties include melting point, boiling point, density, logP, solubility, polarizability, and more. Propersea employs a variety of algorithms for these predictions, including RDKit algorithms, semi-empirical quantum methods, fragment/atom contribution calculations, Bayesian Additive Regression Trees, and transformer neural networks. For properties predicted using Bayesian methods, Propersea provides a 95% confidence interval and a measure of the molecule's similarity to those in the training set.",
            "Additionally, Propersea features a machine learning model for generating IUPAC names from molecular InChI strings. This sequence-to-sequence model, trained on a dataset of 10 million compounds, achieves a 90.7% accuracy rate for complete matches to IUPAC names. While Propersea performs best with organic compounds, its accuracy and reliability are lower for inorganics, organometallics, and inorganic-organic mixtures. This is due to limitations in both the descriptive power of InChI for these molecules and the training dataset's quality and quantity. Future improvements aim to enhance performance for these categories."
        ],
        guides: [
            {
                icon: "language",
                text: "Translating the Molecules: Adapting Neural Machine Translation to Predict IUPAC Names from a Chemical Identifier",
                url: "https://chemrxiv.org/engage/chemrxiv/article-details/60c755d5567dfe21ffec6368"
            },
            {
                icon: "list_alt",
                text: "Fact Sheet",
                url: "https://www.psds.ac.uk/sites/default/files/2021-09/Propersea.pdf"
            }
        ],
        screenshots: [
            "/images/propersea/1.png",
            "/images/propersea/2.png",
            "/images/propersea/3.png",
        ]
    },
    detherm: {
        name: "Detherm",
        shortname: "Detherm",
        logo: "/images/detherm/logo.jpg",
        url: "https://detherm.psdi.ac.uk/",
        about: [
            "DETHERM is a comprehensive database containing thermophysical property data crucial for the design and optimization of chemical processes and apparatus. It includes over 11.84 million data sets for around 76,000 pure substances and 253,000 mixtures, covering a variety of properties such as phase equilibrium data, vapor pressures, critical data, thermodynamic properties, transport properties, surface tensions, and electrolyte data. The database is maintained by DECHEMA in cooperation with external experts, ensuring high-quality, verified data that is continuously updated.",
            "Users can access DETHERM via a web-based interface, allowing easy searches by substance name, synonym, formula, or CAS number. The platform supports detailed queries for specific properties within defined temperature and pressure ranges. Results can be sorted and exported for further analysis or integrated into process simulation software like ASPEN PLUS. This makes DETHERM an essential resource for researchers and engineers involved in chemical process design and development."
        ],
        guides: [
            {
                icon: "language",
                text: "Official Guide",
                url: "https://dechema.de/en/detherm.html"
            },
            {
                icon: "list_alt",
                text: "Fact Sheet",
                url: "https://www.psds.ac.uk/sites/default/files/2019-07/Dethermv6.pdf"
            },
            {
                icon: "smart_display",
                text: "Introductory Video",
                url: "https://youtu.be/XJqP0CtmIX8"
            }
        ],
        screenshots: [
            "/images/detherm/1.png",
            "/images/detherm/2.png",
            "/images/detherm/3.png",
        ]
    },
    icsd: {
        name: "Inorganic Crystal Structure Database",
        shortname: "ICSD",
        logo: "/images/icsd/logo.png",
        url: "https://icsd.psdi.ac.uk/",
        about: [
            "The Inorganic Crystal Structure Database (ICSD) is the world's largest repository of fully identified inorganic crystal structures, managed by FIZ Karlsruhe. The database includes detailed crystallographic data dating back to 1913 and is continuously updated with around 12,000 new structures annually. ICSD encompasses a wide array of structures, including minerals, metals, alloys, and certain metal-organic compounds. Each entry is meticulously quality-checked, ensuring high reliability for users in both scientific research and industrial applications.",
            "ICSD offers powerful search and analysis tools accessible through a web interface, desktop application, or API service. Users can perform complex queries using over 70 characteristics, visualize crystal structures, and simulate powder diffraction patterns. The database supports searches by compound name, chemical formula, and various structural and physical properties. It also includes theoretical structures and supports data mining and computational chemistry applications, allowing users to explore both experimental and predicted structures. These capabilities make ICSD an invaluable resource for crystallographers and materials scientists​."
        ],
        guides: [
            {
                icon: "language",
                text: "Official Guide",
                url: "https://icsd.products.fiz-karlsruhe.de/en/about/about-icsd"
            },
            {
                icon: "list_alt",
                text: "Fact Sheet",
                url: "https://www.psds.ac.uk/sites/default/files/2019-07/ICSDv6.pdf"
            },
            {
                icon: "smart_display",
                text: "Introductory Video",
                url: "https://youtu.be/R7z255mWkGk"
            }
        ],
        screenshots: [
            "/images/icsd/1.png",
            "/images/icsd/2.png",
            "/images/icsd/3.png",
        ]
    },
    chase: {
        name: "Chemical Availability Search",
        shortname: "Chase",
        logo: "/images/chase/logo.png",
        url: "https://chase.psdi.ac.uk/",
        about: [
            "The Chemical Availability Search (ChASe) is a tool designed to help researchers find and compare chemicals from various suppliers. Users can search by substance name, InChI string, InChI key, SMILES notation, and synonyms. Additionally, ChASe supports structure and substructure searches using a built-in structure editor.",
            "ChASe provides comprehensive information on the availability, pricing, and supplier details for over 250,000 unique chemicals. It aggregates data from multiple suppliers into a single database, making it easier for researchers to locate the chemicals needed for their projects​."
        ],
        guides: [
            {
                icon: "list_alt",
                text: "Fact Sheet",
                url: "https://www.psds.ac.uk/sites/default/files/2020-06/Chemical_Availability_Search_2.pdf"
            },
            {
                icon: "smart_display",
                text: "Introductory Video",
                url: "https://youtu.be/E5_oR_7UKD8"
            }
        ],
        screenshots: [
            "/images/chase/1.png",
            "/images/chase/2.png",
            "/images/chase/3.png",
        ]
    },
    crystalworks: {
        name: "CrystalWorks",
        shortname: "CrystalWorks",
        logo: "/images/crystalworks/logo.jpg",
        url: "https://crystalworks.psdi.ac.uk/cgi-bin/rfm/crystalworks_psds",
        about: [
            "CrystalWorks is a tool designed to provide access to extensive crystallographic structural data. It integrates data from both the Cambridge Structural Database (CSD) and the Inorganic Crystal Structure Database (ICSD), allowing users to search and analyze a wide range of crystallographic structures within a single application. This comprehensive resource supports advanced search capabilities, including structure and substructure searches, and offers tools for visualization and analysis of crystal structures.",
            "CrystalWorks is equipped with features to support research and teaching, offering a user-friendly interface for accessing and manipulating crystallographic data. It facilitates complex queries across multiple databases, making it a valuable resource for researchers in fields such as chemistry, materials science, and related disciplines. Users can perform detailed analyses and export data in various formats, enhancing the usability of the structural data for further scientific investigation."
        ],
        guides: [
            {
                icon: "smart_display",
                text: "Introductory Video",
                url: "https://youtu.be/wElVuAx5vEI"
            }
        ],
        screenshots: [
            "/images/crystalworks/1.png",
            "/images/crystalworks/2.png",
            "/images/crystalworks/3.png",
        ]
    },
    converter: {
        name: "Data Converter",
        shortname: "Data Converter",
        logo: "/images/converter/logo.png",
        url: "https://converter.psdi.ac.uk/",
        about: [
            "The Data Converter service allows physical scientists to convert scientific data files between various formats, addressing the challenge of numerous formats and lack of standardization. Users can select input and output formats via a graphical interface, which displays suitable converters and their success rates. Whether converting mass spectrometer RAW files to mzML or adapting data for journal submissions, the service facilitates format transitions and supports data export for easy file download.",
            "One of the key benefits of the Data Converter service is its ability to improve upon existing tools by offering a catalog of scientific data formats and conversion quality indicators. By leveraging Open Babel's extensive library of chemistry data formats such as CIF, PDB, and CML, the service ensures comprehensive coverage of user needs. This is particularly advantageous for researchers who need to adapt their data to meet the specifications of different software or repositories. Additionally, the service includes a feedback mechanism to report missing formats or issues, ensuring continuous improvement and user satisfaction. Overall, the Data Converter service enhances data interoperability, simplifies the conversion process, and supports researchers in maintaining the integrity and usability of their scientific data."
        ],
        guides: [
            {
                icon: "language",
                text: "Data Converter Documentation",
                url: "https://converter.psdi.ac.uk/static/content/documentation.htm"
            }
        ],
        screenshots: [
            "/images/converter/1.png",
            "/images/converter/2.png",
            "/images/converter/3.png",
        ]
    },
    learn: {
        name: "PSDI Learning Service",
        shortname: "Learning",
        logo: "/images/learn/logo.png",
        url: "#",
        about: [
            "Moodle is a learning management system (LMS) used for creating and managing online courses. It includes features such as forums, quizzes, wikis, and assignments, making it suitable for various educational activities. Users can upload and organize course materials, track learner progress with detailed reports, and facilitate communication and collaboration through discussion forums and group activities.",
            "Moodle also supports multimedia integration, allowing users to include videos, audio files, and interactive content in their courses. Its grading and feedback tools help streamline the assessment process. Overall, Moodle provides a comprehensive set of tools for managing online learning and training programs."
        ],
        guides: [
            {
                icon: "language",
                text: "Moodle Documentation",
                url: "https://docs.moodle.org/404/en/Main_page"
            }
        ],
        screenshots: [
            "https://uc.uxpin.com/files/1334901/1281988/1-59be77d21d5eafa414486c9a61e0162b-43a782.png",
            "https://uc.uxpin.com/files/1334901/1281988/2-e7c5fdc3e74af77e6a813dbcf2c31753-cda96b.png",
            "https://uc.uxpin.com/files/1334901/1281988/3-33c7231e625fbdc2d7b9782f7f9ffabc-021ce5.png",
        ]
    },

};

/*
    epsilon: {
        name: "Epsilon",
        shortname: "Epsilon",
        logo: "/images/epsilon/logo.png",
        url: "",
        about: [

        ],
        guides: [
            {
                icon: "language",
                text: "Chemical Availability Search",
                url: "https://www.psds.ac.uk/sites/default/files/2020-06/Chemical_Availability_Search_2.pdf"
            }
        ],
        screenshots: [
            "/images/epsilon/1.png",
            "/images/epsilon/2.png",
            "/images/epsilon/3.png",
        ]
    },
    knowledge: {
        name: "PSDI Knowledgebase",
        shortname: "Knowledge",
        logo: "/images/knowledge/logo.png",
        url: "https://knowledge.psdi.ac.uk/shelves",
        about: [
            "BookStack is a platform for organizing and storing information. It allows users to create and manage content in a wiki-like structure, with pages grouped into chapters and books. The intuitive interface makes it easy to create, edit, and navigate content, ensuring that information is easily accessible and well-organized.",
            "Designed for collaboration, BookStack supports multiple users with different roles and permissions, enabling teams to work together efficiently. It also includes features like search functionality, version control, and page revision history, helping users keep track of changes and maintain accurate documentation."
        ],
        guides: [
            {
                icon: "language",
                text: "Bookstack Documentation",
                url: "https://www.bookstackapp.com/docs/user/content-overview/"
            }
        ],
        screenshots: [
            "https://uc.uxpin.com/files/1334901/1281988/1-59be77d21d5eafa414486c9a61e0162b-43a782.png",
            "https://uc.uxpin.com/files/1334901/1281988/2-e7c5fdc3e74af77e6a813dbcf2c31753-cda96b.png",
            "https://uc.uxpin.com/files/1334901/1281988/3-33c7231e625fbdc2d7b9782f7f9ffabc-021ce5.png",
        ]
    },
*/

export default services;