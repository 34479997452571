import React from 'react';
import Box from '@mui/material/Box';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';

export default function ImageViewer({ imageUrls, onClose, initialIndex }) {
    const [currentImageIndex, setCurrentImageIndex] = React.useState(initialIndex);

    const handleNext = () => {
        setCurrentImageIndex((prevIndex) => prevIndex + 1);
    };

    const handleBack = () => {
        setCurrentImageIndex((prevIndex) => prevIndex - 1);
    };

    return (
        <Box
            position="fixed"
            top={0}
            left={0}
            width="100%"
            height="100vh"
            sx={{ backgroundColor: 'rgba(0, 0, 0, 0.7)', zIndex: 9999 }}
        >
            <Box
                width="100%"
                display="flex"
                justifyContent="flex-end"
            >
                <IconButton
                    onClick={() => {
                        onClose();
                    }}
                >
                    <Icon
                        fontSize="large"
                        sx={{ color: 'white' }}
                    >
                        close
                    </Icon>
                </IconButton>
            </Box>
            <Stack
                direction="row"
                spacing="16px"
                justifyContent="center"
                alignItems="center"
            >
                <IconButton
                    disabled={currentImageIndex === 0}
                    onClick={handleBack}
                    sx={{
                        backgroundColor: 'white',
                        color: 'primary.main',
                        '&:hover': {
                            backgroundColor: 'primary.main',
                            color: 'white',
                        }
                    }}
                >
                    <Icon>arrow_back</Icon>
                </IconButton>
                <img src={imageUrls[currentImageIndex]} alt="" />
                <IconButton
                    disabled={currentImageIndex === imageUrls.length - 1}
                    onClick={handleNext}
                    sx={{
                        backgroundColor: 'white',
                        color: 'primary.main',
                        '&:hover': {
                            backgroundColor: 'primary.main',
                            color: 'white',
                        }
                    }}
                >
                    <Icon>arrow_forward</Icon>
                </IconButton>
            </Stack>
        </Box>
    );
}