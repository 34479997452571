import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import services from '../services';
import Checkbox from '@mui/material/Checkbox';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useState } from 'react';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';


export default function Account() {
  const navigate = useNavigate();

  const [searchQuery, setSearchQuery] = useState('');

  const [value, setValue] = useState(parseInt(localStorage.getItem('accounttab')) || 0);

  useEffect(() => {
    localStorage.setItem('accounttab', value.toString());
  }, [value]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const filteredServices = Object.entries(services).filter(([key, service]) =>
    service.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    service.shortname.toLowerCase().includes(searchQuery.toLowerCase()) ||
    service.about.some((text) => text.toLowerCase().includes(searchQuery.toLowerCase()))
  );

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  return (
    <Box
      width="100%"
      marginTop="30px"
    >
      <Box
        bgcolor="rgb(34, 48, 93)"
        padding="60px"
        sx={{
          position: 'relative',
          marginLeft: '-50vw',
          marginRight: '-50vw',
        }}
      >
        <Container>
          <Grid
            container={true}
            spacing={2}
            paddingLeft="30px"
            paddingRight="30px"
          >
            <Grid
              item={true}
              xs={12}
              sm={6}
            >
              <Stack direction="column" spacing="10px">
                <Typography
                  variant="h3"
                  color="white"
                >
                  Services
                </Typography>
                <Typography
                  variant="body1"
                  color="white"
                >
                  Discover and access a collection of scientific repositories,
                  databases, and tools within the PSDI ecosystem. Sign up for a PSDI
                  account to access non-public services.
                </Typography>
              </Stack>
            </Grid>
            <Grid
              item={true}
              xs={12}
              sm={6}
              alignContent="center"
              justifyContent="right"
            >
              <Box
                justifyContent="right"
                display="flex"
              >
                <TextField
                  id="outlined-start-icon"
                  type="search"
                  variant="outlined"
                  InputProps={{
                    startAdornment: <Icon color="primary">search</Icon>
                  }}
                  sx={{
                    width: '400px',
                    '& .MuiInputBase-input': {
                      paddingLeft: '10px',
                      paddingTop: '14px',
                      borderRadius: '28px'
                    },
                    backgroundColor: 'white',
                    borderRadius: '28px',
                    outline: 'none',
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderRadius: '28px',
                    },
                    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderRadius: '28px',
                    },
                  }}
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>


      <Tabs
        value={value}
        onChange={handleChange}
        centered={true}
        sx={{ paddingTop: '20px', paddingBottom: '20px' }}
      >
        <Tab label="Favourites" sx={{ fontSize: '18px' }} />
        <Tab label="Browse All" sx={{ fontSize: '18px' }} />
      </Tabs>


      {value === 0 && (
        <Grid container={true}>
          {filteredServices.map(([serviceKey, service], index) => (
            <Grid
              item={true}
              xs={12}
              sm="4"
              md="3"
            >
              <Box
                border={1}
                margin="15px"
                borderColor="rgb(218, 218, 218)"
                sx={{
                  borderRadius: '10px',
                  ':hover': { borderColor: '#22305D' }
                }}
                justifyContent="center"
                bgcolor="rgb(255, 255, 255)"
                height="280px"
              >
                <Stack
                  direction="column"
                  height="100%"
                  justifyContent="space-between"
                  paddingTop="20px"
                >
                  <Box
                    width="100%"
                    display="flex"
                    justifyContent="center"
                    sx={{
                      maxHeight: '150px',
                      minHeight: '150px',
                      paddingLeft: '15px',
                      paddingRight: '15px'
                    }}
                    alignItems="center"
                  >
                    <Box
                      component="img"
                      sx={{
                        width: "200px",
                        maxWidth: "200px",
                      }}
                      alt={service.name}
                      src={service.logo}
                    />
                  </Box>
                  <Box
                    width="100%"
                    display="flex"
                    justifyContent="center"
                  >
                    <Typography variant="h6">{service.shortname}</Typography>
                  </Box>
                  <Box
                    width="100%"
                    borderTop={1}
                    borderColor="rgb(218, 218, 218)"
                  >
                    <ButtonGroup
                      variant="contained"
                      aria-label="outlined primary button group"
                      fullWidth={true}
                      sx={{
                        borderRadius: 0,
                        borderBottomLeftRadius: '10px',
                        borderBottomRightRadius: '10px',
                        '.MuiButtonGroup-grouped:not(:last-of-type)': {
                          borderColor: '#DADADA'
                        },
                        boxShadow: '0'
                      }}
                      size="large"
                    >
                      <Button
                        variant="text"
                        fullWidth={true}
                        onClick={() => navigate(`/service/${serviceKey}`)}
                        sx={{
                          borderRadius: 0,
                          borderBottomLeftRadius: '10px',
                          '.MuiTouchRipple-root': {
                            borderRadius: 0,
                            borderBottomLeftRadius: '10px'
                          },
                        }}
                        startIcon={
                          <Icon
                            color="primary"
                            baseClassName="material-icons-outlined"
                          >
                            info
                          </Icon>
                        }
                      >
                        INFO
                      </Button>
                      <Button
                        variant="text"
                        fullWidth={true}
                        onClick={() => window.open(service.url, '_blank')}
                        sx={{
                          borderRadius: 0,
                          borderBottomRightRadius: '10px',
                          '.MuiTouchRipple-root': {
                            borderRadius: 0,
                            borderBottomRightRadius: '10px'
                          }
                        }}
                        endIcon={
                          <Icon
                            color="primary"
                            baseClassName="material-icons-outlined"
                          >
                            arrow_forward
                          </Icon>
                        }
                      >
                        VISIT
                      </Button>
                    </ButtonGroup>
                  </Box>
                </Stack>
              </Box>
            </Grid>
          ))}
        </Grid>
      )}


      {value === 1 && filteredServices.map(([serviceKey, service], index) => (
        <Box width="100%">
          <Box
            border={1}
            margin="15px"
            borderColor="rgb(218, 218, 218)"
            sx={{
              borderRadius: '10px',
              cursor: "pointer",
              ':hover': { borderColor: '#22305D' },
            }}
            bgcolor="rgb(255, 255, 255)"
            padding="20px"
            paddingBottom="20px"
            onClick={() => navigate(`/service/${serviceKey}`)}
          >
            <Grid
              container={true}
              spacing={2}
            >
              <Grid
                item={true}
                xs={12}
                sm="2"
                alignContent="center"
              >
                <img
                  alt={service.name}
                  src={service.logo}
                  width="100%"
                />
              </Grid>
              <Grid
                item={true}
                xs={12}
                sm="9"
                sx={{
                  paddingLeft: "10px",
                  paddingRight: "10px"
                }}
              >
                <Stack
                  direction="column"
                  spacing="5px"
                  justifyContent="center"
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                  >
                    <Typography variant="h4">
                      {service.name}
                    </Typography>
                    <Checkbox
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      icon={
                        <Icon
                          color="inherit"
                          sx={{ color: '#DADADA' }}
                          fontSize="large"
                        >
                          star
                        </Icon>
                      }
                      checkedIcon={
                        <Icon
                          color="warning"
                          sx={{ color: '#FFB600' }}
                          fontSize="large"
                        >
                          star
                        </Icon>
                      }
                    />
                  </Stack>
                  <Typography
                    variant="body1"
                    sx={{
                      display: '-webkit-box',
                      WebkitLineClamp: 3,
                      WebkitBoxOrient: 'vertical',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',

                    }}
                  >
                    {service.about}
                  </Typography>
                </Stack>
              </Grid>
              <Grid
                item={true}
                xs={12}
                sm="1"
                alignContent="center"
                justifyContent="center"
              >
                <Box
                  width="100%"
                  display="flex"
                  justifyContent="center"
                >
                  <Button
                    color="primary"
                    size="large"
                    variant="text"
                    sx={{ borderRadius: '60px', height: "60px", paddingRight: '10px' }}
                    className="chevronbutton"
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        display: { xs: 'block', sm: 'none' },
                        paddingLeft: '10px'
                      }}
                    >
                      More
                    </Typography>
                    <Icon
                      color="inherit"
                      fontSize="large"
                    >
                      chevron_right
                    </Icon>
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      ))}
    </Box>
  );
};