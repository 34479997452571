import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import services from '../services';
import Checkbox from '@mui/material/Checkbox';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useState } from 'react';


export default function Account() {
  const navigate = useNavigate();

  const [value, setValue] = useState(parseInt(localStorage.getItem('accounttab')) || 0);

  useEffect(() => {
    localStorage.setItem('accounttab', value.toString());
  }, [value]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      width="100%"
      marginTop="30px"
    >
      <Typography variant="h3">Welcome</Typography>
      <Typography variant="body1">
        Welcome to your PSDI account. Browse and access your favorite services
        and explore the full range of available resources within the PSDI
        ecosystem.
      </Typography>


      <Tabs
        value={value}
        onChange={handleChange}
        centered={true}
        sx={{ paddingTop: '20px', paddingBottom: '20px' }}
      >
        <Tab label="Favourites" sx={{ fontSize: '18px' }} />
        <Tab label="Browse All" sx={{ fontSize: '18px' }} />
      </Tabs>


      {value === 0 && (

        <Grid container={true}>
          {Object.entries(services).map(([serviceKey, service], index) => (
            <Grid
              item={true}
              xs={12}
              sm="4"
              md="3"
            >
              <Box
                border={1}
                margin="15px"
                borderColor="rgb(218, 218, 218)"
                sx={{
                  borderRadius: '10px',
                  ':hover': { borderColor: '#22305D' }
                }}
                justifyContent="center"
                bgcolor="rgb(255, 255, 255)"
                height="280px"
              >
                <Stack
                  direction="column"
                  height="100%"
                  justifyContent="space-between"
                  paddingTop="20px"
                >
                  <Box
                    width="100%"
                    display="flex"
                    justifyContent="center"
                    sx={{
                      maxHeight: '150px',
                      minHeight: '150px',
                      paddingLeft: '15px',
                      paddingRight: '15px'
                    }}
                    alignItems="center"
                  >
                    <Box
                      component="img"
                      sx={{
                        width: "200px",
                        maxWidth: "200px",
                      }}
                      alt={service.name}
                      src={service.logo}
                    />
                  </Box>
                  <Box
                    width="100%"
                    display="flex"
                    justifyContent="center"
                  >
                    <Typography variant="h6">{service.shortname}</Typography>
                  </Box>
                  <Box
                    width="100%"
                    borderTop={1}
                    borderColor="rgb(218, 218, 218)"
                  >
                    <ButtonGroup
                      variant="contained"
                      aria-label="outlined primary button group"
                      fullWidth={true}
                      sx={{
                        borderRadius: 0,
                        borderBottomLeftRadius: '10px',
                        borderBottomRightRadius: '10px',
                        '.MuiButtonGroup-grouped:not(:last-of-type)': {
                          borderColor: '#DADADA'
                        },
                        boxShadow: '0'
                      }}
                      size="large"
                    >
                      <Button
                        variant="text"
                        fullWidth={true}
                        onClick={() => navigate(`/service/${serviceKey}`)}
                        sx={{
                          borderRadius: 0,
                          borderBottomLeftRadius: '10px',
                          '.MuiTouchRipple-root': {
                            borderRadius: 0,
                            borderBottomLeftRadius: '10px'
                          },
                          '&:hover': {
                            backgroundColor: 'rgba(0, 0, 0, 0.04)',
                          },
                        }}
                        startIcon={
                          <Icon
                            color="primary"
                            baseClassName="material-icons-outlined"
                          >
                            info
                          </Icon>
                        }
                      >
                        INFO
                      </Button>
                      <Button
                        variant="text"
                        fullWidth={true}
                        onClick={() => window.open(service.url, '_blank')}
                        sx={{
                          borderRadius: 0,
                          borderBottomRightRadius: '10px',
                          '.MuiTouchRipple-root': {
                            borderRadius: 0,
                            borderBottomRightRadius: '10px'
                          }
                        }}
                        endIcon={
                          <Icon
                            color="primary"
                            baseClassName="material-icons-outlined"
                          >
                            arrow_forward
                          </Icon>
                        }
                      >
                        VISIT
                      </Button>
                    </ButtonGroup>
                  </Box>
                </Stack>
              </Box>
            </Grid>
          ))}
        </Grid>
      )}


      {value === 1 && Object.entries(services).map(([serviceKey, service], index) => (

        <Box width="100%">
          <Box
            border={1}
            margin="15px"
            borderColor="rgb(218, 218, 218)"
            sx={{
              borderRadius: '10px',
              cursor: "pointer",
              ':hover': { borderColor: '#22305D' },
              ':hover .chevronbutton': {
                backgroundColor: "#22305D",
                color: "#FFFFFF !important"
              }
            }}
            bgcolor="rgb(255, 255, 255)"
            padding="20px"
            paddingBottom="20px"
            onClick={() => navigate(`/service/${serviceKey}`)}
          >
            <Grid
              container={true}
              spacing={2}
            >
              <Grid
                item={true}
                xs={12}
                sm="2"
                alignContent="center"
              >
                <img
                  alt={service.name}
                  src={service.logo}
                  width="100%"
                />
              </Grid>
              <Grid
                item={true}
                xs={12}
                sm="9"
                sx={{
                  paddingLeft: "10px",
                  paddingRight: "10px"
                }}
              >
                <Stack
                  direction="column"
                  spacing="5px"
                  justifyContent="center"
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                  >
                    <Typography variant="h4">
                      {service.name}
                    </Typography>
                    <Checkbox
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      icon={
                        <Icon
                          color="inherit"
                          sx={{ color: '#DADADA' }}
                          fontSize="large"
                        >
                          star
                        </Icon>
                      }
                      checkedIcon={
                        <Icon
                          color="warning"
                          sx={{ color: '#FFB600' }}
                          fontSize="large"
                        >
                          star
                        </Icon>
                      }
                    />
                  </Stack>
                  <Typography
                    variant="body1"
                    sx={{
                      display: '-webkit-box',
                      WebkitLineClamp: 3,
                      WebkitBoxOrient: 'vertical',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',

                    }}
                  >
                    {service.about}
                  </Typography>
                </Stack>
              </Grid>
              <Grid
                item={true}
                xs={12}
                sm="1"
                alignContent="center"
                justifyContent="center"
              >
                <Box
                  width="100%"
                  display="flex"
                  justifyContent="center"
                >
                  <Button
                    color="primary"
                    size="large"
                    variant="text"
                    sx={{ borderRadius: '60px', height: "60px", paddingRight: '10px' }}
                    className="chevronbutton"
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        display: { xs: 'block', sm: 'none' },
                        paddingLeft: '10px'
                      }}
                    >
                      More
                    </Typography>
                    <Icon
                      color="inherit"
                      fontSize="large"
                    >
                      chevron_right
                    </Icon>
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>

        </Box>
      ))}
    </Box>
  );
};